<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addMerchant'})">
      بيانات المتجر
    </dashboard-page-title>
<!--    {{storeActive.logo}}-->
<!--    {{storeActive}}-->
     <form @submit.prevent="save">
        <b-row>
          <b-col md="8">
            <div>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i> معلومات التاجر</h5>
                </template>
                <b-row>
                    <b-col  cols="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="userInfo.name"
                        validate="required"
                        name="id"
                        :disabled="true"
                        placeholder="أدخل الاسم" label="الإسم"
                    />
                  </b-col>
                  <b-col  cols="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="storeActive.category.name"
                        validate="required"
                        name="id"
                        :disabled="true"
                        placeholder="أدخل الاسم" label="ضمن متاجر"
                    />
                  </b-col>
                  <b-col  md="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="userInfo.phone"
                        :validate="'required|numeric'"
                        name="id"
                        :disabled="true"
                        placeholder="Ex: 0109564546" label="رقم الجوال"
                    />
                  </b-col>
                  <b-col  md="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="userInfo.national_id"
                        :validate="'required|numeric'"
                        name="id"
                        :disabled="true"
                        placeholder="Ex: 454846511154" label="رقم الهوية"
                    />
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i> روابط المتجر</h5>
                </template>
                <b-row>
                  <b-col md="12" class="mb-5" v-for="(item, key) in storeActive.social_media_links" :key="key">
                    <b-form-group
                                               :label-for="'URL Links'"
                        class="position-relative"
                    >
              <span class="text-danger deleteLabelButton cursor-pointer" v-if="key != 0" @click="deleteLink(key)">مسح
              </span>
                      <b-input-group class="d-flex gap_1">
                        <validation-provider
                            #default="{ errors }"
                            :name="`URL Link ${key + 1}`"
                            :rules="{ required:true }"
                            class="flex-grow-1"
                        >
                          <b-form-input
                              id="mm"
                              v-model="item.link"
                              :class="[{ 'is-invalid': errors.length > 0 }]"
                              :placeholder="'Ex: https://www.google.com'"
                              :disabled="!item.selectSocial"
                              :style="{ color: 'var(--iq-secondary) !important' }"
                          />
                        </validation-provider>
                        <template #prepend class="w-25">
                          <b-dropdown
                              :text="item.selectSocial ? item.selectSocial : 'Choose  '"
                              class="selectWithInput iq-border-radius-5 bg-white border"
                              variant="white"
                          >
                            <b-dropdown-item v-for="(i, keyLink) in filterLinks" :key="keyLink" @click="item.selectSocial = i">
                              {{i}}
                            </b-dropdown-item>
                          </b-dropdown>
                        </template>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" v-if="allLinks.length !== storeActive.social_media_links.length"><span class="text-warning cursor-pointer" @click="addNewLink">+ أضف منصة أخرى</span></b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-store"></i> معلومات المتجر</h5>
                </template>
                <b-row>
                  <b-col  cols="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="storeActive.ar_name"
                        validate="required"
                        name="id"
                        placeholder="أدخل الاسم" :label="$t('admins.storeNameInArabic')"
                    />
                  </b-col>
                  <b-col  cols="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="storeActive.en_name"
                        validate="required"
                        name="id"
                        placeholder="أدخل الاسم" :label="$t('admins.storeNameInEnglish')"
                    />
                  </b-col>
                  <b-col md="3">
                    <label class="joining-label">{{ $t('admins.theCity') }}</label>
                      <main-select
                        class="mb-3 joining-label"
                        :validate="'required'"
                        :name="$t('admins.theCity')"
                        :reduce="city => city.id"
                        :placeholder="$t('admins.theCity')"
                        :options="allCity"
                        v-model="storeActive.city.id"
                        label="name"
                        dir="rtl"
                      />
                  </b-col>
                  <b-col md="9">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="storeActive.address"
                        :validate="'required'"
                        name="id"
                        :disabled="true"
                        label="العنوان"
                        placeholder="العنوان"
                    />
                  </b-col>
                  <b-col md="12">
                    <textarea-form placeholder="الوصف" v-model="storeActive.about" label="عن المتجر" name="about" validate="required"></textarea-form>
                  </b-col>
                  <b-col md="12">
                    <textarea-form placeholder="الوصف" v-model="storeActive.policies" label="الشروط و الأساسيات" name="policies" validate="required"></textarea-form>
                  </b-col>
<!--                  <b-col md="6">
                    <input-form
                        class="mb-3 joining-label"
                        v-model="storeActive.category.name"
                        :validate="'required'"
                        name="id"
                        :disabled="true"
                        label="نوع المتجر"
                        placeholder="نوع المتجر"
                    />
                  </b-col>-->
                </b-row>
              </b-card>
              <!-- <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-image"></i>  الصور</h5>
                </template>
                <b-row>
                  <b-col md="12" class="mb-4">
                    <cropper-images
                        label="شعار المتجر"
                        nameOfImage="image.jpg"
                        @cropper-save="saveLogoImage"
                        :progressLoading="progressLogo"
                        :multi="false"
                        :imageUrl="storeActive.logo"
                    />
                  </b-col>
                  <b-col md="12" class="mb-4">
                    <cropper-images
                        label="غلاف المتجر"
                        nameOfImage="image.jpg"
                        @cropper-save="saveCoverImage"
                        :progressLoading="progressCover"
                        :showProgress="true"
                        :multi="false"
                        :imageUrl="storeActive.cover"
                    />
                  </b-col>
                </b-row>
              </b-card> -->
            </div>
          </b-col>
          <b-col md="3">
            <b-card class="iq-border-radius-10 mb-3 main-actions">
              <template v-slot:header>
                <h5 class="text-primary">
                  <i class="las la-save"></i>
                  النشر
                </h5>
              </template>
              <div v-if="loadingSubmitForm">
                <b-button variant="primary" class="w-100" disabled>
                  <spinner-loading text="يتم الحفظ"></spinner-loading>
                </b-button>
              </div>
              <div class="d-flex justify-content-end gap_1" v-else>
                  <b-button variant="primary"  class="w-100" type="submit" > حفظ </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </form>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import settingsServices from '../services/settings'
import mainInfoMixins from '@/mixins/mainInfo'
export default {
  mixins: [mainInfoMixins],
  components: { },
  mounted () {
    core.index()
  },
  watch: {
  },
  data () {
    return {
      allLinks: [
        'Website',
        'Facebook',
        'Instagram'
      ],
      userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).data.user : {},
      market: {
        logo: '',
        cover: '',
        social_media_links: [
          {
            selectSocial: '',
            link: ''
          }
        ],
        about: '',
        policies: ''
      },
      allCity: [],
      loadingSubmitForm: false,
      progressCover: 0,
      progressLogo: 0,
      progressCommercialRegistry: 0,
      progressVatCertificate: 0
    }
  },
  computed: {
    filterLinks () {
      var newLinksArr = [...this.allLinks]
      this.storeActive.social_media_links.forEach(e => {
        if (newLinksArr.includes(e.selectSocial)) {
          var socialIndex = newLinksArr.findIndex(social => social === e.selectSocial)
          newLinksArr.splice(socialIndex, 1)
        }
      })
      return newLinksArr
    }
  },
  methods: {
    // getStoreInfo () {
    //   settingsServices.getMerchantInfo().then(res => {
    //   })
    // },
    getAllCities () {
      settingsServices.getAllCities().then(res => {
        this.allCity = res.data.data
      })
    },
    saveLogoImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      formData.append('app_name', 'marketplace')
      formData.append('directory', 'stores')
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressLogo = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.storeActive.logo = res.data.url
        this.showSuccessUploadFile()
      })
    },
    saveCoverImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      formData.append('app_name', 'marketplace')
      formData.append('directory', 'stores')
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressCover = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.storeActive.cover = res.data.url
        this.showSuccessUploadFile()
      })
    },
    addNewLink () {
      this.storeActive.social_media_links.push({
        selectSocial: '',
        link: ''
      })
    },
    deleteLink (key) {
      this.storeActive.social_media_links.splice(key, 1)
    },
    save () {
      this.loadingSubmitForm = true
      settingsServices.uploadMarketplaceFiles({
        logo: this.storeActive.logo,
        cover: this.storeActive.cover,
        en_name: this.storeActive.en_name,
        ar_name: this.storeActive.ar_name,
        social_media_links: this.storeActive.social_media_links,
        about: this.storeActive.about,
        policies: this.storeActive.policies,
        city_id: this.storeActive.city.id
      }).then(res => {
        core.showSnackbar('success', 'Data Updated Successfully')
        this.getInfo()
      }).finally(() => {
        this.loadingSubmitForm = false
      })
    }
  },
  created () {
    this.getAllCities()

    // this.getStoreInfo()
  }
}
</script>
<style>
.main-actions {
  position: sticky !important;
  top: 0px;
}
.deleteLabelButton {
  position: absolute;
  left: 10px;
  top: -30px;
}
</style>
